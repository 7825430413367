import { LoadingProgrammatic, DialogProgrammatic } from 'buefy';
import APITrainings from '@app/services/API/Trainings';

export default {
  staticExportQuizAnzswers(quizName, trainingUuid, trainingItemId, customer) {
    let target;
    let customerUuid;

    if (customer) {
      target = customer.firstname;
      customerUuid = customer.uuid;
    } else {
      target = 'tous les apprenants';
    }

    const loader = LoadingProgrammatic.open();
    APITrainings.exportItemQuizAnswers(trainingUuid, trainingItemId, customerUuid)
      .then(() => DialogProgrammatic.alert({
        message: `
          Un export CSV listant les réponses de ${target}
          au quiz <strong>${quizName}</strong> vous a été envoyé par email.
        `,
      }))
      .finally(() => loader.close());
  },
};
