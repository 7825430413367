<script>
export default {
  props: {
    isSection: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dripConfig: this.getDripConfig(this.value),
      trainingItem: this.getTrainingItem(this.value),
    };
  },
  computed: {
    target() {
      return this.isSection ? 'ce chapitre' : 'cette leçon';
    },
  },
  watch: {
    value(value) {
      this.dripConfig = this.getDripConfig(value);
      this.trainingItem = this.getTrainingItem(value);
    },
  },
  methods: {
    getDripConfig(value) {
      if (!value) {
        return 'NONE';
      }

      if (value && value.drip_date) {
        return 'DATE';
      }

      if (value && value.drip_manually) {
        return 'MANUAL';
      }

      return value.drip_delay > 0 ? 'DELAY' : 'NONE';
    },
    getTrainingItem(value) {
      if (value) {
        const drip_date = value.drip_date ? this.$moment.utc(value.drip_date).toDate() : null;

        return {
          drip_delay: value.drip_delay,
          drip_date,
          drip_manually: value.drip_manually,
        };
      }

      return {
        drip_delay: null,
        drip_date: null,
        drip_manually: null,
      };
    },
    onConfigChange() {
      if (this.dripConfig === 'NONE') {
        this.trainingItem.drip_delay = 0;
        this.trainingItem.drip_date = null;
        this.trainingItem.drip_manually = false;
      } else if (this.dripConfig === 'DELAY') {
        this.trainingItem.drip_delay = 1;
        this.trainingItem.drip_date = null;
        this.trainingItem.drip_manually = false;
      } else if (this.dripConfig === 'MANUAL') {
        this.trainingItem.drip_delay = 0;
        this.trainingItem.drip_date = null;
        this.trainingItem.drip_manually = true;
      } else {
        this.trainingItem.drip_delay = 0;
        this.trainingItem.drip_date = new Date();
        this.trainingItem.drip_manually = false;
      }

      this.onUpdate();
    },
    onUpdate() {
      const trainingItem = { ...this.trainingItem };
      if (trainingItem.drip_date) {
        trainingItem.drip_date = this.$moment(trainingItem.drip_date)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
      }

      this.$emit('input', trainingItem);
    },
  },
};
</script>

<template>
  <div>
    <b-field>
      <template #label>
        Quand est-ce que l'apprenant peut accéder à {{ target }} ?
      </template>
      <b-radio-button v-model="dripConfig" @input="onConfigChange" native-value="NONE">
        Immédiatement
      </b-radio-button>
      <b-radio-button v-model="dripConfig" @input="onConfigChange" native-value="DELAY">
        Après un délai
      </b-radio-button>
      <b-radio-button v-model="dripConfig" @input="onConfigChange" native-value="DATE">
        À une date précise
      </b-radio-button>
      <b-radio-button v-model="dripConfig" @input="onConfigChange" native-value="MANUAL">
        Après déblocage manuel
      </b-radio-button>
    </b-field>
    <b-field v-if="dripConfig === 'DELAY'" key="drip_delay" label-for="drip_delay" :addons="false">
      <template #label>
        Renseignez un délai (en jours)
      </template>
      <div v-show="trainingItem.drip_delay" class="box is-warning mt-3">
        Chaque inscrit aura accès à {{ target }},
        <strong>
          {{ trainingItem.drip_delay }} jour{{ trainingItem.drip_delay > 1 ? 's' : '' }}
          après son inscription</strong>.
      </div>
      <b-input
        type="number"
        v-model.number="trainingItem.drip_delay"
        @input="trainingItem.drip_delay != 0 && onUpdate()"
        id="drip_delay"
        min="1"
        max="365"
        required
      />
    </b-field>
    <b-field
      v-else-if="dripConfig === 'DATE'"
      key="drip_date"
      label="Renseignez une date"
      label-for="drip_date"
      :addons="false">
      <div v-show="trainingItem.drip_date" class="box is-warning">
        Tous les inscrits auront accès à {{ target }} en même temps, à partir du
        <strong>{{
          trainingItem.drip_date | momentFromUTC | moment('DD/MM/YY [à] HH[h]mm')
        }}</strong>.
      </div>
      <b-datetimepicker
        v-model="trainingItem.drip_date"
        id="drip_date"
        icon="calendar-alt"
        position="is-top-left"
        inline
        @input="onUpdate"
      />
    </b-field>
    <p v-else-if="dripConfig === 'MANUAL'" class="box is-warning">
      L'apprenant n'aura <strong>accès à ce contenu que lorsque vous l'aurez débloqué
      manuellement pour lui</strong>, depuis la fiche apprenant.
      Cette option peut vous être utile pour débloquer {{ target }} après
      un coaching individuel par exemple.
    </p>
    <p v-else class="box is-warning">
      Chaque inscrit aura immédiatement accès à {{ target }}.
    </p>
  </div>
</template>
