<script>
import Draggable from 'vuedraggable';
import trainingsMixin, { trainingItemUtils } from '@app/mixins/trainings';
import TrainingItemSingle from '@app/components/TrainingItemSingle.vue';
import TrainingCreateDropdown from '@app/components/TrainingCreateDropdown.vue';
import TrainingEditEnforceProgression from '@app/components/TrainingEditEnforceProgression.vue';

export default {
  mixins: [trainingsMixin, trainingItemUtils],
  components: {
    TrainingEditEnforceProgression,
    TrainingCreateDropdown,
    TrainingItemSingle,
    Draggable,
  },
  head: {
    title: 'Contenu',
  },
  computed: {
    hasPercentPlan() {
      return this.$store.getters['auth/hasPercentPlan'];
    },
    isNotRecurringPlan() {
      return this.$store.getters['auth/isNotRecurringPlan'];
    },
    currentTrainingItems: {
      get() {
        return this.$store.state.trainings.current.items;
      },
      set(trainingItems) {
        this.$store.dispatch('trainings/reorderItems', {
          uuid: this.currentTraining.uuid,
          trainingItems,
        })
          .then(() => this.$showMessage.success());
      },
    },
  },
  watch: {
    'currentTrainingItems.length': {
      handler(val, oldVal) {
        if (val > oldVal) {
          const lastItem = this.currentTrainingItems[val - 1];
          this.$nextTick(() => this.$scrollTo(`#training-item-${lastItem.id}`));
        }
      },
    },
  },
  methods: {
    scrollToTrainingItemIfpossible() {
      const { hash } = this.$route;

      if (hash) {
        this.$scrollTo(hash, { duration: 0 });
        this.$router.replace({ hash: '' });
      }
    },
    goPro() {
      this.$showMessage.goPro(() => {
        this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
      });
    },
  },
  mounted() {
    this.scrollToTrainingItemIfpossible();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'training') {
      this.$nextTick(() => this.scrollToTrainingItemIfpossible());
    }

    next();
  },
};
</script>

<template>
  <section class="page">
    <header v-if="currentTrainingItems.length" class="is-flex is-justify-content-space-between">
      <TrainingEditEnforceProgression />
      <TrainingCreateDropdown />
    </header>

    <div v-else class="content has-text-centered my-20 w-640 mx-auto">
      <h2>Ajoutez vos contenus pédagogiques</h2>
      <p class="help">
        Créez des leçons ou des quiz contenant du texte, des liens, des images,
        des vidéos et plus encore !
      </p>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Chapitres (facultatifs)</h3>
          <p class="help">
            Pour <strong>structurer le parcours de formation</strong>,
            vous pouvez créer des Chapitres.
            Chaque chapitre peut contenir plusieurs leçons et quiz.
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button type="is-primary" @click="addSection()">
            Ajouter un chapitre
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Leçons</h3>
          <p class="help">
            Créez vos leçons avec tous les contenus multimédia que vous souhaitez :
            <strong>textes</strong>, <strong>images</strong>, <strong>vidéos</strong>,
            <strong>citations</strong>, <strong>annexes</strong>, <strong>liens externes</strong>
            , etc...
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button
            type="is-primary"
            @click="addGeneric()"
          >
            Créer une leçon
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Quiz multimédia</h3>
          <p class="help">
            Besoin d'évaluer vos apprenants ?
            Rajoutez des quiz pour les aider à valider leurs acquis.
            Créez <strong>des quiz ludiques grâce aux images, vidéos et même audios.</strong>
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button
            type="is-primary"
            @click="addQuiz()"
          >
            Élaborer un quiz
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Devoirs</h3>
          <p class="help">
            <strong>Évaluez, commentez et notez</strong> les travaux individuels
            de vos apprenants en créant un devoir.
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button v-if="isNotRecurringPlan" type="is-primary" @click="goPro">
            Créer un devoir
          </b-button>
          <b-button
            v-else
            type="is-primary"
            @click="addAssignment()"
          >
            Créer un devoir
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Tâches</h3>
          <p class="help">
            Confiez <strong>des tâches & missions</strong> à vos apprenants
            pour les faire passer à l'action (cases à cocher).
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button v-if="hasPercentPlan" type="is-primary" @click="goPro">
            Créer des tâches à faire
          </b-button>
          <b-button
            v-else
            type="is-primary"
            @click="addTasks()"
          >
            Créer des tâches à faire
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Scorm</h3>
          <p class="help">
            Vous avez déjà vos <strong>contenus aux formats SCORM 1.2 ou SCORM 2004</strong> ?
            Importez-les tout simplement dans vos leçons Teachizy.
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button type="is-primary" @click="addScorm">
            Importer un contenu
          </b-button>
        </nav>
      </div>
    </div>

    <div class="mt-5">
      <draggable v-model="currentTrainingItems" handle=".handle">
        <TrainingItemSingle
          v-for="item in currentTrainingItems"
          :key="item.id"
          :training="currentTraining"
          :item="item"
        />
      </draggable>
    </div>

    <nav v-if="currentTrainingItems.length" class="mt-10 buttons is-centered">
      <TrainingCreateDropdown position="is-top-left" />
    </nav>

    <router-view />
  </section>
</template>

<style lang="scss" scoped>
.page {
  ::v-deep .sortable-ghost {
    border: 2px solid $primary;
  }
}
</style>
