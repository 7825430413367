<script>
import Draggable from 'vuedraggable';
import trainingItemsMixin from '@app/mixins/trainingItems';
import TrainingItemSingleNav from '@app/components/TrainingItemSingleNav.vue';
import TrainingItemSingleInfos from '@app/components/TrainingItemSingleInfos.vue';

export default {
  mixins: [trainingItemsMixin],
  components: {
    Draggable,
    TrainingItemSingleNav,
    TrainingItemSingleInfos,
  },
  i18n: {
    messages: {
      fr: {
        content: '{n} contenu pédagogique | {n} contenu pédagogique | {n} contenus pédagogiques',
      },
    },
  },
  data() {
    return {
      isLoading: false,
      sectionIsOpen: false,
    };
  },
  computed: {
    isSection() {
      return this.item.type === 'SECTION';
    },
    items: {
      get() {
        return this.isSection ? this.item.children : [this.item];
      },
      set(trainingItems) {
        this.$store.dispatch('trainings/reorderItems', {
          uuid: this.training.uuid,
          trainingItems,
          trainingItemParent: this.isSection ? this.item : null,
        })
          .then(() => this.$showMessage.success());
      },
    },
  },
  methods: {
    toggleSection() {
      this.sectionIsOpen = !this.sectionIsOpen;
    },
    deleteItem(item) {
      const itemType = this.$i18n.t('training_items.this_type');

      const message = `
        Attention ! <br>
        Le contenu et tous les commentaires liés à ${itemType[item.type]}
        seront <strong>définitivement supprimés</strong>. Les <strong>logs de connexion</strong>
        rattachés à ${itemType[item.type]} seront également supprimés
        <strong>pour tous les apprenants</strong>.
      `;

      this.$buefy.dialog.confirm({
        message,
        type: 'is-danger',
        focusOn: 'cancel',
        onConfirm: () => {
          this.isLoading = true;
          this.$store.dispatch('trainings/removeItem', {
            uuid: this.training.uuid,
            trainingItem: item,
          })
            .finally(() => (this.isLoading = false));
        },
      });
    },
  },
};
</script>

<template>
  <div
    :id="`training-item-${item.id}`"
    class="tiscomp box p-3"
    :class="{
      'px-0 pt-0': isSection,
      'pb-0': (isSection && !sectionIsOpen),
      'pb-3': (isSection && sectionIsOpen),
    }">
    <b-loading :active="isLoading" />
    <header v-if="isSection" class="p-4 mb-0 bbottom-grey">
      <div class="columns is-multiline is-vcentered">
        <div class="column is-narrow">
          <b-button
            :title="sectionIsOpen ? 'Replier' : 'Déplier'"
            :icon-left="sectionIsOpen ? 'chevron-up' : 'chevron-down'"
            @click="toggleSection"
          />
        </div>
        <div class="column is-narrow pl-0">
          <b-button
            class="handle cursor-grab"
            type="is-text"
            icon-left="grip-lines"
            title="Cliquez et déplacez la souris pour ré-ordonner"
          />
        </div>
        <div class="column pl-0">
          <h2 class="tiscomp_name is-size-5 has-text-weight-bold">
            <a href="#" class="has-text-black" @click.prevent="toggleSection">
              {{ item.name }}
            </a>
            <span class="is-size-8 has-text-weight-normal">
              ({{ $tc('content', items.length) }})
            </span>
            <b-button
              type="is-text"
              class="tiscomp_quickedit py-0 h-auto"
              icon-left="pencil-alt"
              size="is-small"
              title="Modifier le titre"
              @click.stop.prevent="editItemTitle"
            />
            <span v-if="item.draft" class="tag has-text-weight-normal mr-2">
              <b-icon
                class="mr-1 is-size-9"
                icon="circle"
                type="is-warning"
              />
              En brouillon
            </span>
            <span
              v-if="$store.getters['auth/isNotPercentPlan'] && item.drip_date"
              class="tag is-outlined has-text-weight-normal"
            >
              <b-icon class="mr-1" icon="calendar-day" />
              Accessible le {{ item.drip_date | momentFromUTC | moment('DD/MM/YY') }}
            </span>
            <span
              v-else-if="$store.getters['auth/isNotPercentPlan'] && item.drip_delay"
              class="tag is-outlined has-text-weight-normal"
            >
              <b-icon class="mr-1" icon="calendar-day" />
              Délai avant accès : {{ item.drip_delay }}
              jour{{ item.drip_delay > 1 ? 's' : '' }}
            </span>
            <span
              v-else-if="$store.getters['auth/isNotPercentPlan'] && item.drip_manually"
              class="tag is-outlined has-text-weight-normal"
            >
              <b-icon class="mr-1" icon="calendar-day" />
              Accessible après un déblocage manuel
            </span>
          </h2>
        </div>
        <div class="column is-narrow">
          <TrainingItemSingleNav
            :item="item"
            :training="training"
            :delete-item="deleteItem"
          />
        </div>
      </div>
    </header>

    <div
      v-if="!items.length"
      v-show="sectionIsOpen"
      class="notification is-light mx-4 mt-4 has-text-black">
      Pas encore de contenu dans ce chapitre.
    </div>

    <draggable
      v-if="isSection"
      v-show="sectionIsOpen"
      v-model="items"
      handle=".subhandle"
    >
      <TrainingItemSingleInfos
        v-for="one in items"
        :key="one.id"
        :item="one"
        :training="training"
        :delete-item="deleteItem"
        class="pt-4 pl-10 pr-4"
        title-tag="h3"
        handle-class="subhandle"
      />
    </draggable>
    <template v-else>
      <TrainingItemSingleInfos
        v-for="one in items"
        :key="one.id"
        :item="one"
        :training="training"
        :delete-item="deleteItem"
        title-tag="h2"
        handle-class="handle"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.tiscomp {
  ::v-deep .sortable-ghost {
    border: 2px solid $primary;
  }

  &_quickedit {
    visibility: hidden;
  }

  &_name:hover &_quickedit {
    visibility: visible;
  }
}
</style>
