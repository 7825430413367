<script>
import trainingsMixin, { trainingItemUtils } from '@app/mixins/trainings';

export default {
  mixins: [trainingsMixin, trainingItemUtils],
  props: {
    parentId: {
      type: Number,
      default: null,
    },
    position: {
      type: String,
      default: 'is-bottom-left',
    },
  },
};
</script>

<template>
  <b-dropdown aria-role="menu" class="comp has-text-left" :position="position">
    <template #trigger>
      <b-button type="is-primary" icon-right="plus">
        <template v-if="!parentId">
          Ajouter un contenu pédagogique
        </template>
        <template v-else>
          Ajouter
        </template>
      </b-button>
    </template>
    <b-dropdown-item
      v-if="!parentId"
      aria-role="menuitem"
      @click="addSection(parentId)"
    >
      <p class="is-size-7 has-text-weight-bold flex-between-center">
        <span>Chapitre (facultatif)</span>

        <b-tooltip position="is-left" multilined>
          <template #content>
            Regroupez vos leçons sous un même chapitre afin de mieux structurer votre formation
          </template>
          <b-icon
            class="has-text-black70"
            pack="far"
            icon="question-circle"
          />
        </b-tooltip>
      </p>
    </b-dropdown-item>
    <b-dropdown-item v-if="!parentId" separator />
    <b-dropdown-item aria-role="menuitem" @click="addGeneric(parentId)">
      <p class="is-size-7 has-text-weight-bold flex-between-center">
        <span>Leçon</span>

        <b-tooltip position="is-left" multilined>
          <template #content>
            Créez une leçon avec du texte riche, des images, des vidéos et ressources externes
          </template>
          <b-icon
            class="has-text-black70"
            pack="far"
            icon="question-circle"
          />
        </b-tooltip>
      </p>
    </b-dropdown-item>
    <b-dropdown-item separator />
    <b-dropdown-item aria-role="menuitem" @click="addQuiz(parentId)">
      <p class="is-size-7 has-text-weight-bold flex-between-center">
        <span>Quiz multimédia</span>

        <b-tooltip position="is-left" multilined>
          <template #content>
            Ajoutez un quiz ludique, à choix multiple ou choix unique, avec des images, vidéos et même audios !
          </template>
          <b-icon
            class="has-text-black70"
            pack="far"
            icon="question-circle"
          />
        </b-tooltip>
      </p>
    </b-dropdown-item>
    <b-dropdown-item separator />
    <b-dropdown-item aria-role="menuitem" @click="addAssignment(parentId)">
      <p class="is-size-7 has-text-weight-bold flex-between-center">
        <span>
          Devoir
          <AppFeatureBadge class="ml-2" position="is-top" />
        </span>

        <b-tooltip position="is-left" multilined>
          <template #content>
            Donnez des consignes à vos apprenants pour
            qu'ils vous soumettent leurs travaux et notez-les en retour.
          </template>
          <b-icon
            class="has-text-black70"
            pack="far"
            icon="question-circle"
          />
        </b-tooltip>
      </p>
    </b-dropdown-item>
    <b-dropdown-item separator />
    <b-dropdown-item aria-role="menuitem" @click="addTasks(parentId)">
      <p class="is-size-7 has-text-weight-bold flex-between-center">
        <span>
          Tâches
          <AppFeatureBadge class="ml-2" position="is-top" />
        </span>

        <b-tooltip position="is-left" multilined>
          <template #content>
            Confiez des tâches &amp; missions à vos apprenants pour les faire passer à l'action (cases à cocher)
          </template>
          <b-icon
            class="has-text-black70"
            pack="far"
            icon="question-circle"
          />
        </b-tooltip>
      </p>
    </b-dropdown-item>
    <b-dropdown-item separator />
    <b-dropdown-item aria-role="menuitem" @click="addScorm(parentId)">
      <p class="is-size-7 has-text-weight-bold flex-between-center">
        <span>
          Contenu SCORM
          <AppFeatureBadge class="ml-2" position="is-top" planLock="EXPERT" />
          <span class="tag is-danger is-light is-size-8 ml-5">Bêta</span>
        </span>
        <b-tooltip position="is-left" multilined>
          <template #content>
            Importez vos contenus au format SCORM (1.2 ou 2004) pour créer vos leçons en quelques clics
          </template>
          <b-icon
            class="has-text-black70"
            pack="far"
            icon="question-circle"
          />
        </b-tooltip>
      </p>
    </b-dropdown-item>
  </b-dropdown>
</template>

<style lang="scss" scoped>
.comp {
  ::v-deep {
    .dropdown-menu {
      width: 360px;
    }
    a.dropdown-item {
      white-space: normal;
      padding-right: pxToRem(12px);
    }
  }
}
</style>
