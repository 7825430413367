<script>
import TrainingItemDripField from '@app/components/TrainingItemDripField.vue';

export default {
  components: { TrainingItemDripField },
  props: {
    isSection: {
      type: Boolean,
      default: () => false,
    },
    training: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        drip_delay: this.item ? this.item.drip_delay : null,
        drip_date: this.item ? this.item.drip_date : null,
        drip_manually: this.item ? this.item.drip_manually : null,
      },
    };
  },
  methods: {
    handle() {
      if (this.isSection) {
        this.$buefy.dialog.confirm({
          type: 'is-success',
          message: `
            <div class="content">
              <p>
                Voulez-vous appliquer cela à toutes les leçons inclus dans ce chapitre ?
              </p>
              <p class="has-text-weight-bold">
                Vous pourrez toujours configurer l'accès à chaque leçon individuellement si besoin.
              </p>
            </div>
          `,
          confirmText: 'Oui',
          cancelText: 'Non',
          focusOn: 'cancel',
          onConfirm: () => this.sendForm(true),
          onCancel: () => this.sendForm(),
        });
        return;
      }

      this.sendForm();
    },
    sendForm(give_drip_values) {
      const { uuid } = this.training;
      const trainingItem = { ...this.form, give_drip_values };
      const trainingItemId = this.item && this.item.id;

      this.isLoading = true;
      this.$store.dispatch('trainings/updateItem', { uuid, trainingItemId, trainingItem })
        .then(() => (this.$emit('update')))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="handle">
    <h2 class="title is-4">
      Configurez l'accès à {{ isSection ? 'ce chapitre' : 'cette leçon' }}
    </h2>
    <GoProWrapper>
      <TrainingItemDripField v-model="form" :isSection="isSection" />
      <div class="buttons is-right mt-3">
        <b-button type="is-primary" native-type="submit" :loading="isLoading">
          Mettre à jour
        </b-button>
      </div>
    </GoProWrapper>
  </form>
</template>
