<script>
import APITrainings from '@app/services/API/Trainings';

export default {
  props: {
    training: {
      type: Object,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    hasDetailsOpen: false,
    pagedFeedbacks: null,
  }),
  computed: {
    canSeeFeedback() {
      if (!this.training.feedback_options.enabled) {
        return false;
      }

      if (this.$store.getters['auth/hasPercentPlan']) {
        return false;
      }

      if (
        this.training.feedback_options.options.type !== 'RANGE'
        && !this.$store.getters['auth/hasExpertPlan']
      ) {
        return false;
      }

      return true;
    },
    isRange() {
      return this.training.feedback_options.options.type === 'RANGE';
    },
    feedbackValues() {
      return this.training.feedback_options.options.values;
    },
    nbFeedbacks() {
      return this.trainingItem
        .feedbacks
        .reduce((prev, curr) => (prev + curr.nb_customers), 0);
    },
    score() {
      let nbCustomers = 0;
      const scoreSum = this.trainingItem
        .feedbacks
        .reduce((prev, curr) => {
          if (curr.nb_customers <= 0) {
            return prev;
          }

          nbCustomers += curr.nb_customers;
          return prev + (curr.nb_customers * Number(curr.score));
        }, 0);

      if (nbCustomers <= 0) {
        return null;
      }

      return Math.round((scoreSum / nbCustomers) * 100) / 100;
    },
  },
  methods: {
    openDetails() {
      this.loadFeedbacks()
        .then(() => (this.hasDetailsOpen = true));
    },
    loadFeedbacks(next = false) {
      const loader = this.$buefy.loading.open();

      if (next && this.pagedFeedbacks && this.pagedFeedbacks.links.next) {
        return APITrainings.getTrainingItemFeedbacks(
          this.pagedFeedbacks.links.next,
        )
          .then((data) => {
            data.data = [...this.pagedFeedbacks.data, ...data.data];
            this.pagedFeedbacks = data;
          })
          .finally(() => loader.close());
      }

      return APITrainings.getTrainingItemFeedbacks(
        this.training.uuid, this.trainingItem.id,
      )
        .then((data) => (this.pagedFeedbacks = data))
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <div v-if="canSeeFeedback">
    <b-tooltip>
      <template #content>
        Voir le détail des réactions
      </template>
      <b-button
        type="is-warning"
        class="h-auto is-size-8 py-1px has-text-dark"
        :icon-left="feedbackValues.k1.icon"
        :icon-pack="feedbackValues.k1.icon_type"
        outlined
        @click="openDetails"
      >
        <template v-if="isRange">
          <template v-if="score">
            {{ score }} / 5
          </template>
          <template v-else>
            Aucune réaction
          </template>
        </template>
        <template v-else>
          {{ nbFeedbacks }} réaction{{ nbFeedbacks > 1 ? 's' : '' }}
        </template>
      </b-button>
    </b-tooltip>

    <b-modal
      v-if="hasDetailsOpen"
      width="640"
      has-modal-card
      :active.sync="hasDetailsOpen"
    >
      <AppCardModal>
        <template #title>
          Liste des réactions
          <template v-if="$store.getters['auth/hasExpertPlan']">
            ({{ pagedFeedbacks.meta.total }})
          </template>
        </template>
        <template #body>
          <GoProWrapper planLock="EXPERT" :hasSep="false">
            <ul v-if="pagedFeedbacks.data.length">
              <li
                v-for="(feedback, k) in pagedFeedbacks.data"
                :key="k"
                class="bbottom-grey pb-3 mb-3"
              >
                <p class="is-size-9 has-text-dark">
                  Le {{ feedback.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </p>
                <p class="mt-1 has-text-weight-bold">
                  <router-link
                    :to="{
                      name: 'customer',
                      params: {
                        uuid: feedback.customer && feedback.customer.uuid
                      }
                    }"
                  >
                    {{ feedback.customer.firstname }}
                    {{ feedback.customer.lastname }}
                  </router-link>
                </p>
                <p class="is-flex is-align-items-center mt-2">
                  Réaction :
                  <b-rate
                    v-if="isRange"
                    class="ml-3"
                    :value="feedback.score * 1"
                    :icon-pack="feedbackValues.k1.icon_type"
                    :icon="feedbackValues.k1.icon"
                    size="is-medium"
                    spaced
                    disabled
                  />
                  <template v-else>
                    <b-icon
                      class="feedback-btn is-size-5 mx-2"
                      :pack="feedbackValues[feedback.score].icon_type"
                      :icon="feedbackValues[feedback.score].icon"
                    />
                    {{ feedbackValues[feedback.score].text }}
                  </template>
                </p>
                <p v-if="feedback.comment" class="mt-2">
                  Détail : {{ feedback.comment }}
                </p>
              </li>
            </ul>
            <p v-else>
              Il n'y a aucune réaction pour le moment sur cette leçon.
            </p>
            <p v-show="pagedFeedbacks.links.next" class="has-text-centered mt-3">
              <b-button type="is-primary" @click="loadFeedbacks(true)">
                Voir plus de réactions
              </b-button>
            </p>
          </GoProWrapper>
        </template>
        <template #foot>
          <b-button type="is-primary" @click="hasDetailsOpen = false">
            Fermer
          </b-button>
        </template>
      </AppCardModal>
    </b-modal>
  </div>
</template>
