<script>
import quizMixin from '@app/mixins/quiz';
import TrainingItemSingleNav from '@app/components/TrainingItemSingleNav.vue';
import TrainingItemLink from '@app/components/TrainingItemLink.vue';
import TrainingItemFeedbackResult from '@app/components/TrainingItemFeedbackResult.vue';

export default {
  mixins: [quizMixin],
  components: {
    TrainingItemSingleNav,
    TrainingItemLink,
    TrainingItemFeedbackResult,
  },
  getQuestionsCount(props) {
    return props.item.quiz_details
      ? props.item.quiz_details.length
      : props.item.questions_count;
  },
  featureBlockedAlert(parent) {
    parent.$buefy.dialog.alert(`
      <p>
        Votre pack actuel ne vous permet pas d'avoir accès
        à cette fonctionnalité.
      </p>
      <p class="mt-2">
        Pour éviter que vos apprenants soient bloqués,
        nous vous conseillons de mettre ce contenu en brouillon.
      </p>
    `);
  },
};
</script>

<template functional>
  <article
    :id="`training-item-${props.item.id}`"
    class="media"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
  >
    <div class="media-left is-flex">
      <a
        class="cursor-grab mr-3 has-text-black"
        :class="props.handleClass"
        title="Cliquez et déplacez la souris pour ré-ordonner"
      >
        <b-icon icon="grip-lines" />
      </a>
      <figure
        class="image is-32x32 bradius-8 has-text-centered has-background-grey"
      >
        <b-icon
          class="valign-middle h-full"
          :icon="parent.$constants.icons[props.item.type]"
        />
      </figure>
    </div>
    <div class="media-content">
      <component :is="titleTag || 'h2'" class="is-size-7 has-text-weight-bold break-words">
        <b-tooltip v-if="props.item.draft" class="mr-2">
          <template #content>
            En brouillon
          </template>
          <b-icon
            class="is-size-6"
            icon="circle"
            type="is-warning"
          />
        </b-tooltip>
        <component
          :is="$options.components.TrainingItemLink"
          :training="props.training"
          :item="props.item"
        />
      </component>
      <div
        v-if="parent.$store.getters['auth/isNotPercentPlan'] && props.item.min_complete_duration"
        class="tag is-outlined mt-2 mr-2"
      >
        <b-tooltip multilined>
          <template #content>
            L'apprenant doit passer au minimum
            {{ props.item.min_complete_duration }}
            minute{{ props.item.min_complete_duration > 1 ? 's' : '' }}
            sur cette leçon, avant de pouvoir
            la passer à "terminée".
          </template>
          <b-icon class="mr-1" icon="clock" />
          {{ props.item.min_complete_duration }}
          minute{{ props.item.min_complete_duration > 1 ? 's' : '' }}
        </b-tooltip>
      </div>
      <div
        v-if="parent.$store.getters['auth/isNotPercentPlan'] && props.item.drip_date"
        class="tag is-outlined mt-2"
      >
        <b-tooltip multilined>
          <template #content>
            <i18n path="training_items.access_date_info" tag="span">
              {{ props.item.drip_date | momentFromUTC | moment('DD/MM/YY [à] HH[h]mm') }}
            </i18n>
          </template>
          <b-icon class="mr-1" icon="calendar-day" />
          Accessible le {{ props.item.drip_date | momentFromUTC | moment('DD/MM/YY') }}
        </b-tooltip>
      </div>
      <div
        v-else-if="parent.$store.getters['auth/isNotPercentPlan'] && props.item.drip_delay"
        class="tag is-outlined mt-2"
      >
        <b-tooltip multilined>
          <template #content>
            <i18n path="training_items.access_delay_info" tag="span">
              {{ parent.$tc('training_items.day', props.item.drip_delay) }}
            </i18n>
          </template>
          <b-icon class="mr-1" icon="calendar-day" />
          Délai avant accès : {{ props.item.drip_delay }}
          jour{{ props.item.drip_delay > 1 ? 's' : '' }}
        </b-tooltip>
      </div>
      <div
        v-else-if="parent.$store.getters['auth/isNotPercentPlan'] && props.item.drip_manually"
        class="tag is-outlined mt-2"
      >
        <b-tooltip multilined>
          <template #content>
            <i18n path="training_items.access_manually_info" tag="span">
              {{ parent.$tc('training_items.access_manually_info') }}
            </i18n>
          </template>
          <b-icon class="mr-1" icon="calendar-day" />
          Accès manuel
        </b-tooltip>
      </div>
      <p class="is-size-8 mt-2">
        <span v-if="props.item.type != 'SCORM'">
          {{ props.item.resources_count }} {{ props.item.resources_count > 1 ? 'annexes' : 'annexe' }}
        </span>

        <template v-if="props.item.type == 'QUIZ'">
          •
          {{ $options.getQuestionsCount(props) }}
          {{ $options.getQuestionsCount(props) > 1 ? 'questions' : 'question' }}

          • <router-link :to="{
              name: 'training_uuid_quiz_answers',
              params: {uuid: props.training.uuid, id: props.item.id}
            }">
              Voir les réponses ({{ props.item.customer_answers_count || 0 }})
            </router-link>
          <template v-if="props.item.customer_answers_count">
            • <a
              href="#"
              @click.prevent="$options.staticExportQuizAnzswers(
                props.item.name, props.training.uuid, props.item.id,
              )">
              Exporter les réponses
            </a>
          </template>
        </template>
        <template v-if="props.item.type == 'ASSIGNMENT'">
          •
          <router-link :to="{
            name: 'training_uuid_assignment_submissions',
            params: {uuid: props.training.uuid, id: props.item.id}
          }">
            Voir les soumissions ({{ props.item.assignments_count || 0 }})
          </router-link>

          •
          <router-link :to="{
            name: 'training_uuid_assignment_submissions',
            params: {uuid: props.training.uuid, id: props.item.id}
          }">
            À corriger ({{ props.item.assignments_to_validate_count || 0 }})
          </router-link>
        </template>
      </p>
      <p class="mt-2">
        <b-tooltip
          v-if="!props.item.draft &&
          (
            (parent.$store.getters['auth/isNotRecurringPlan']
              && ['ASSIGNMENT'].includes(props.item.type))
            ||
            (parent.$store.getters['auth/hasPercentPlan']
             && ['TASKS'].includes(props.item.type))
            ||
            (!parent.$store.getters['auth/hasExpertPlan']
             && ['SCORM'].includes(props.item.type))
          )
          "
          multilined
          class="mr-2"
        >
          <template #content>
            Cliquez afin de savoir pourquoi
            cette fonctionnalité est bloquée
            et comment résoudre le problème.
          </template>

          <a
            class="tag is-danger"
            href="#"
            @click.prevent="$options.featureBlockedAlert(parent)"
          >
            <b-icon class="mr-3" icon="info-circle" />
            Fonctionnalité bloquée
          </a>
        </b-tooltip>

        <component
          :is="$options.components.TrainingItemFeedbackResult"
          class="is-inline-block"
          :training="props.training"
          :trainingItem="props.item"
        />
      </p>
    </div>
    <component
      :is="$options.components.TrainingItemSingleNav"
      class="media-right"
      :item="props.item"
      :training="props.training"
      :delete-item="props.deleteItem"
    />
  </article>
</template>
