import { render, staticRenderFns } from "./TrainingItemLink.vue?vue&type=template&id=2506e882&scoped=true&"
import script from "./TrainingItemLink.vue?vue&type=script&lang=js&"
export * from "./TrainingItemLink.vue?vue&type=script&lang=js&"
import style0 from "./TrainingItemLink.vue?vue&type=style&index=0&id=2506e882&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2506e882",
  null
  
)

export default component.exports