export default {
  props: {
    training: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editItemTitle() {
      this.$buefy.dialog.prompt({
        title: 'Modifier le titre',
        inputAttrs: { value: this.item.name, maxlength: 128 },
        confirmText: 'Valider',
        onConfirm: (name) => {
          const loader = this.$buefy.loading.open();
          const { uuid } = this.training;
          const trainingItem = { name };
          const trainingItemId = this.item.id;
          this.$store
            .dispatch('trainings/updateItem', { uuid, trainingItemId, trainingItem })
            .finally(() => loader.close());
        },
      });
    },
  },
};
