<script>
import trainingItemsMixin from '@app/mixins/trainingItems';

const routeNames = {
  GENERIC: 'training_uuid_editor',
  QUIZ: 'training_uuid_quiz',
  TASKS: 'training_uuid_tasks',
  ASSIGNMENT: 'training_uuid_assignment',
};

export default {
  mixins: [trainingItemsMixin],
  methods: {
    editorNotAllowed() {
      return this.$buefy.dialog.alert(`
        Les contenus SCORM ne sont pas modifiables via l'éditeur de leçon de Teachizy.
      `);
    },
  },
  computed: {
    link() {
      return {
        name: routeNames[this.item.type],
        params: {
          uuid: this.training.uuid, id: this.item.id,
        },
      };
    },
  },
};
</script>

<template>
  <span v-if="item.type != 'SCORM'">
    <router-link class="tilcomp has-text-black is-inline-block" :to="link">
      {{ item.name }}
      <b-button
        type="is-text"
        class="tilcomp_quickedit py-0 h-auto"
        icon-left="pencil-alt"
        size="is-small"
        title="Modifier le titre"
        @click.stop.prevent="editItemTitle"
      />
    </router-link>
  </span>
  <span v-else>
    <a
      @click.stop.prevent="editorNotAllowed"
      href="#"
      class="tilcomp has-text-black is-inline-block"
    >
      {{ item.name }}
      <b-button
        type="is-text"
        class="tilcomp_quickedit py-0 h-auto"
        icon-left="pencil-alt"
        size="is-small"
        title="Modifier le titre"
        @click.stop.prevent="editItemTitle"
      />
    </a>
  </span>
</template>

<style lang="scss" scoped>
.tilcomp {
  &_quickedit {
    visibility: hidden;
  }

  &:hover &_quickedit {
    visibility: visible;
  }
}
</style>
