<script>
export default {
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    enforce_progression_order: {
      get() {
        return this.currentTraining.enforce_progression_order;
      },
      set(enforce_progression_order) {
        const loader = this.$buefy.loading.open();
        const { uuid } = this.currentTraining;
        const training = { enforce_progression_order };
        this.$store.dispatch('trainings/update', { uuid, training, isCurrent: true })
          .finally(() => loader.close());
      },
    },
  },
};
</script>

<template>
  <div class="is-flex is-align-items-center">
    <b-switch v-model="enforce_progression_order">
      Imposer la lecture ordonnée
    </b-switch>
    <b-tooltip multilined>
      <b-icon icon="question-circle" pack="far" size="is-medium" />
      <template #content>
        En activant cette option, les apprenants devront
        suivre les leçons dans l'ordre.
      </template>
    </b-tooltip>
  </div>
</template>
