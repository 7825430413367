<script>
import APIStore from '@app/services/API/Store';
import debounce from 'lodash/debounce';

export default {
  props: {
    training: {
      type: Object,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customers: { data: [], links: {}, meta: {} },
      selectedCustomers: [],
      params: {
        q: '',
        page: 1,
        customer_type: 'STUDENT',
        training_uuid: this.training.uuid,
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    search(params = {}) {
      this.selectedCustomers = [];

      params = { ...this.params, ...params };
      params.q === '' && delete params.q;

      this.$refs.body.scroll(0, 0);
      const loader = this.$buefy.loading.open({ container: this.$el });
      APIStore.getCustomers(this.authStore.uuid, params)
        .then((data) => (this.customers = data))
        .finally(() => loader.close());
    },
    onQueryChange: debounce(function() {
      this.search();
    }, 300),
    confirmSelection() {
      this.$emit('select', [...this.selectedCustomers]);
      this.$emit('close');
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<template>
  <section class="ticlcomp modal-card is-relative">
    <header class="modal-card-head">
      <h2 class="modal-card-title">
        Sélectionnez un ou plusieurs apprenants ({{ selectedCustomers.length }})
      </h2>
    </header>
    <form ref="body" class="modal-card-body">
      <p>
        Ce sont les apprenants de la leçon
        <strong>{{ trainingItem.name }}</strong>.
      </p>
      <b-field class="mt-5">
        <b-input
          v-model.trim="params.q"
          placeholder="Filtrez par email, prénom ou nom"
          @input="onQueryChange"
        />
      </b-field>

      <ul v-if="customers.data.length" class="mt-5">
        <li
          v-for="customer in customers.data"
          :key="customer.uuid"
          class="p-4 mb-3 has-background-light bradius-4">
          <b-checkbox
            class="is-align-items-flex-start"
            v-model="selectedCustomers"
            :native-value="customer.uuid">
            <strong>
              {{ customer.firstname }} {{ customer.lastname }}
            </strong>
            <br>
            ({{ customer.email }})
          </b-checkbox>
        </li>
      </ul>
      <p v-else class="notification">
        Il n'y a pas de résultats
      </p>

      <b-pagination
        v-model="params.page"
        :total="customers.meta.total"
        :per-page="customers.meta.per_page"
        aria-next-label="Page suivante"
        aria-previous-label="Page précédente"
        aria-page-label="Page"
        aria-current-label="Page courante"
        @change="search({ page: $event })"
      />
    </form>
    <footer class="modal-card-foot btop-grey is-justify-content-flex-end">
      <b-button type="is-text" @click="$emit('close')">
        Annuler
      </b-button>
      <b-button
        type="is-primary"
        :disabled="selectedCustomers.length <= 0"
        @click="confirmSelection">
        Sélectionner ({{ selectedCustomers.length }})
      </b-button>
    </footer>
  </section>
</template>

<style lang="scss" scoped>
.ticlcomp {
  ::v-deep .b-checkbox.checkbox input[type=checkbox] + .check {
    border-color: $primary;
  }
}
</style>
