<script>
import APIStore from '@app/services/API/Store';
import TrainingItemCustomerList from '@app/components/TrainingItemCustomerList.vue';
import TrainingItemSectionForm from '@app/components/TrainingItemSectionForm.vue';
import TrainingCreateDropdown from '@app/components/TrainingCreateDropdown.vue';
import TrainingItemDripForm from '@app/components/TrainingItemDripForm.vue';
import DuplicateTrainingItemModal from '@app/components/DuplicateTrainingItemModal.vue';

const routeNames = {
  GENERIC: 'training_uuid_editor',
  QUIZ: 'training_uuid_quiz',
  TASKS: 'training_uuid_tasks',
  ASSIGNMENT: 'training_uuid_assignment',
};

export default {
  components: { TrainingCreateDropdown, TrainingItemDripForm },
  props: {
    training: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    deleteItem: {
      type: Function,
      required: true,
    },
  },
  i18n: {
    messages: {
      fr: {
        edit_drip: 'Modifier le délai d\'accès',
      },
    },
  },
  data() {
    return {
      newParentId: '',
      hasDripModalOpen: false,
      hasStudentsModalOpen: false,
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    sections() {
      const data = this.$store.getters['trainings/currentSectionItems'];
      return data && data.filter((v) => v.id !== this.item.id);
    },
    theItemTrans() {
      return this.$t(`training_items.the_type.${this.item.type}`);
    },
    pageURL() {
      const { item } = this;

      if (!item.id || item.type === 'SECTION') {
        return null;
      }

      const url = this.$store.getters['auth/url'];
      const q = `?p=${this.$store.state.auth.previewToken}`;

      return `//${url}/mon-espace/formations/${this.training.slug}/elements/${item.id}${q}`;
    },
  },
  methods: {
    handleEdit() {
      if (this.item.type === 'SCORM') {
        this.$buefy.dialog.alert(`
        Les contenus SCORM ne sont pas modifiables via l'éditeur de leçon de Teachizy.
      `);
        return;
      }

      if (this.item.type !== 'SECTION') {
        this.$router.push({
          name: routeNames[this.item.type],
          params: {
            uuid: this.training.uuid,
            id: this.item.id,
          },
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: TrainingItemSectionForm,
          hasModalCard: true,
          trapFocus: true,
          props: { item: this.item },
        });
      }
    },
    changeLocation() {
      const loader = this.$buefy.loading.open();
      this.$store.dispatch('trainings/updateItem', {
        uuid: this.training.uuid,
        trainingItemId: this.item.id,
        trainingItem: {
          parent_id: this.newParentId,
        },
      })
        .then(() => this.$store.dispatch('trainings/find', this.training.uuid))
        .then(() => (this.newParentId = ''))
        .finally(() => loader.close());
    },
    updateDraftState(uuid, draft) {
      const trainingItem = { draft };
      const trainingItemId = this.item.id;
      const loader = this.$buefy.loading.open();
      this.$store.dispatch('trainings/updateItem', { uuid, trainingItemId, trainingItem })
        .then(() => this.$showMessage.success())
        .finally(() => loader.close());
    },
    preview() {
      if (!this.pageURL) {
        return;
      }

      this.$buefy.dialog.confirm({
        message: this.$t('messages.alert_preview_training_item'),
        type: 'is-warning',
        confirmText: 'Ok ! Voir la page',
        focusOn: 'cancel',
        onConfirm: () => window.open(this.pageURL),
      });
    },
    duplicate(trainingUuid, trainingItem) {
      if (this.$store.getters['auth/hasPercentPlan']) {
        this.$showMessage.goPro(() => {
          this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
        });
        return;
      }

      this.$buefy.modal.open({
        parent: this,
        component: DuplicateTrainingItemModal,
        hasModalCard: true,
        props: { trainingUuid, trainingItem },
      });
    },
    unlockItemForStudent(item) {
      this.hasStudentsModalOpen = true;
      const modal = this.$buefy.modal.open({
        component: TrainingItemCustomerList,
        parent: this,
        hasModalCard: true,
        props: {
          training: this.training,
          trainingItem: item,
        },
        events: {
          select: (selectedCustomers) => {
            const loader = this.$buefy.loading.open();
            Promise.all(selectedCustomers.map((customerUUID) => APIStore.unblockCustomerTrainingItem(
              this.authStore.uuid,
              customerUUID,
              this.training.uuid,
              item.id,
            )))
              .then(() => this.$showMessage.success())
              .finally(() => loader.close());
          },
        },
      });

      console.log(modal);
    },
  },
};
</script>

<template>
  <nav class="is-flex">
    <div class="has-text-right">
      <b-tooltip label="Modifier">
        <b-button
          type="is-text"
          icon-left="edit"
          @click="handleEdit"
        />
      </b-tooltip>
      <b-tooltip>
        <template #content>
          <span v-t="'edit_drip'" />
        </template>
        <b-button
          type="is-text"
          icon-left="calendar-day"
          @click="hasDripModalOpen = true"
        />
      </b-tooltip>
      <b-tooltip v-if="item.type != 'SECTION'">
        <b-button type="is-text" icon-left="eye" @click="preview" />
        <template #content>
          Prévisualiser {{ theItemTrans }}
        </template>
      </b-tooltip>
      <b-tooltip>
        <b-button type="is-text" icon-left="copy" @click="duplicate(training.uuid, item)" />
        <template #content>
          Dupliquer {{ theItemTrans }}
        </template>
      </b-tooltip>
      <b-dropdown position="is-bottom-left" aria-role="menu" append-to-body>
        <template #trigger>
          <b-button type="is-text" icon-left="ellipsis-h" />
        </template>
        <b-dropdown-item v-if="item.draft" aria-role="menuitem" @click="updateDraftState(training.uuid, false)">
          <b-icon icon="circle" type="is-success" class="is-size-9" />
          Publier
        </b-dropdown-item>
        <b-dropdown-item v-else aria-role="menuitem" @click="updateDraftState(training.uuid, true)">
          <b-icon icon="circle" type="is-warning" class="is-size-9" />
          Mettre en brouillon
        </b-dropdown-item>
        <b-dropdown-item
          v-if="item.type != 'SECTION' && (item.drip_date || item.drip_delay)"
          aria-role="menuitem"
          @click="unlockItemForStudent(item)">
          Débloquer le délai d'accès pour un apprenant
        </b-dropdown-item>
        <b-dropdown-item class="has-text-danger" aria-role="menuitem" @click="deleteItem(item)">
          Supprimer
        </b-dropdown-item>
      </b-dropdown>
      <form v-if="item.type != 'SECTION'" class="mt-2" @submit="changeLocation">
        <b-select
          class="ml-auto"
          style="max-width: 172px"
          v-model="newParentId"
          :disabled="!sections.length"
          @input="changeLocation"
        >
          <option value="">Déplacer vers...</option>
          <option v-for="one in sections" :key="one.id" :value="one.id">
            {{ one.name }}
          </option>
          <option :value="null">En dehors de ce chapitre</option>
        </b-select>
      </form>
    </div>
    <TrainingCreateDropdown v-if="item.type == 'SECTION'" class="ml-3" :parentId="item.id" />
    <b-modal :active.sync="hasDripModalOpen" width="728px">
      <TrainingItemDripForm
        :isSection="item.type == 'SECTION'"
        :training="training"
        :item="item"
        @update="hasDripModalOpen = false"
      />
    </b-modal>
  </nav>
</template>
