<script>
export default {
  props: {
    trainingUuid: {
      type: String,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chosenTrainingUuid: this.trainingUuid,
      hasConfirmShown: false,
      isLoading: false,
    };
  },
  computed: {
    transTheItem() {
      return this.$t(`training_items.this_type.${this.trainingItem.type}`);
    },
    trainings() {
      return this.$store.state.trainings.list;
    },
  },
  methods: {
    confirm() {
      this.hasConfirmShown = true;
    },
    duplicate() {
      const { chosenTrainingUuid: targetUuid, trainingItem } = this;
      const uuid = this.trainingUuid;
      const loader = this.$buefy.loading.open();
      this.$store.dispatch('trainings/duplicateItem', { uuid, targetUuid, trainingItem })
        .then((newTrainingItem) => {
          this.$showMessage.success();
          this.$nextTick(() => {
            if (newTrainingItem.parent_id) {
              this.$scrollTo(`#training-item-${newTrainingItem.id}`);
              return;
            }

            const pos = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
            window.scroll(0, pos);
            this.$emit('close');
          });
        })
        .finally(() => loader.close());
    },
  },
  created() {
    this.isLoading = true;
    this.$store.dispatch('trainings/fetch')
      .then(() => {
        if (!this.trainings || this.trainings.data.length <= 0) {
          this.hasConfirmShown = true;
        }
      })
      .finally(() => (this.isLoading = false));
  },
};
</script>

<template>
  <div>
    <AppCardModal v-if="!hasConfirmShown">
      <template #title>
        Dupliquer {{ transTheItem }}
      </template>
      <template #body>
        <b-skeleton v-if="isLoading" height="100" />
        <b-field v-else :label="`Où voulez-vous dupliquer ${transTheItem} ?`">
          <b-select v-model="chosenTrainingUuid" :loading="isLoading" expanded>
            <template v-for="one in trainings.data">
              <option v-if="one.uuid != trainingUuid" :key="`if${one.uuid}`" :value="one.uuid">
                {{ one.name }}
              </option>
              <option v-else :key="`else${one.uuid}`" :value="trainingUuid">
                Dans cette formation ({{ one.name }})
              </option>
            </template>
          </b-select>
        </b-field>
      </template>
      <template #foot>
        <b-button type="is-text" @click="$emit('close')">
          Annuler
        </b-button>
        <b-button type="is-primary" @click="confirm">
          Dupliquer
        </b-button>
      </template>
    </AppCardModal>
    <AppCardModal v-else>
      <template #body>
        Confirmez-vous la duplication ?
      </template>
      <template #foot>
        <b-button type="is-text" @click="$emit('close')">
          Annuler
        </b-button>
        <b-button type="is-primary" @click="duplicate">
          Je confirme !
        </b-button>
      </template>
    </AppCardModal>
  </div>
</template>
